import styled from "styled-components";
import { green, orange } from "../../styles/variables.style";

export const TestOutilFormWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  hr {
    border: none;
    height: 2px;
    width: 400px;
  }

  strong {
    font-size: 22px;
    span {
      color: ${orange};
    }
  }

  .description {
    font-size: 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      margin: 10px 0 0 0 !important;
      height: 30px;
      width: 300px;
      border-radius: 0px;
      border-color: black;
      &::placeholder {
        font-weight: bold;
      }
    }
    .ant-btn {
      font-size: 16px;
      padding: 10px;
      width: 200px;
      margin: 0 10px;
      font-weight: bold;
    }
    .ant-form-item-control-input {
      display: flex !important;
      justify-content: center;
    }
  }

  &.article {
    margin-bottom: 50px;
    border: 1px solid ${green};
    hr,
    .ant-btn {
      background: ${green};
    }
  }

  &.home {
    padding: 20px 30px 0 30px;
    border: 1px solid ${orange};
    hr,
    .ant-btn {
      background: ${orange};
    }
    .ant-btn {
      margin: 0px !important;
    }
    @media (max-width: 1024px) {
      margin: 30px 0;
    }
  }

  @media (max-width: 1024px) {
    hr {
      width: 100px !important;
    }
  }
`;
