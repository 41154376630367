import React, { useState } from "react";
import { HomeWrapper } from "./Home.style";
import { Button, Input, Modal } from "antd";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import Aos from "aos";
import ArticleGuides from "../../components/guides/ArticleGuides";
import Footer from "../../components/global/Footer/Footer";
import { Steps, Collapse, Tabs } from "antd";
import { useMediaQuery } from "react-responsive";
import Image from "next/image";
import { faBuilding, faCreditCard } from "@fortawesome/free-regular-svg-icons";
import fille from "../../../public/home/fille2.png";
import orias from "../../../public/home/orias.png";
import cncgp from "../../../public/home/cncgp.png";
import rgpd from "../../../public/home/rgpd.png";
import agreger from "../../../public/home/agreger.webp";
import suivre_votre_patrimoine from "../../../public/home/suivre_votre_patrimoine.webp";
import analyse_diversification from "../../../public/home/analyse_diversification.webp";
import calcul_rendement_locatif from "../../../public/home/calcul_rendement_locatif.webp";
import frais_transmission from "../../../public/home/frais_transmission.webp";
import comment_ca_marche from "../../../public/home/comment_ca_marche.jpg";
import iceberg from "../../../public/home/iceberg.png";
import solutions_du_marche from "../../../public/home/solutions_du_marche.webp";
import pourquoi_un_outil from "../../../public/home/pourquoi_un_outil.png";
import TestOutilForm from "../../components/TestOutilForm/TestOutilForm";
import { getCookie } from "cookies-next";

export default function Home({ articles }) {
  const { TabPane } = Tabs;

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const showGirl = useMediaQuery({ minWidth: 1500 });

  useEffect(() => {
    Aos.init({ duration: isDesktop ? 1500 : 0 });
  }, []);

  const { Step } = Steps;
  const { Panel } = Collapse;

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <HomeWrapper>
      <div className="textG">
        <div className="middle_text">
          <h1 className="p_text">
            Gérez tout votre patrimoine
            <br />
            où vous le souhaitez
          </h1>
          <div className="txtGray">
            Un <strong>outil de gestion de patrimoine</strong> simple et
            puissant qui vous offre un suivi et des analyses précises pour vous
            accompagner au quotidien.
            <br />
            <br />
            Le seul outil accessible qui compare toutes les
            <br />
            <strong> solutions d'investissements immobilières </strong>
            ou <strong>financières</strong>
            <br />
            pour prendre les bonnes décisions.
          </div>
          <div className="begin">
            <div>
              <Button
                type="Primary"
                className="btn_header"
                onClick={() => setIsModalVisible(true)}
              >
                Tester notre outil
              </Button>
            </div>
            <div>
              <Button
                type="Primary"
                className="bl"
                onClick={() => setIsModalVisible(true)}
              >
                Gérer avec un conseiller
              </Button>
            </div>
          </div>

          <div className="elemCoche">
            <div>
              <FontAwesomeIcon icon={faCheck} id="checkbox" />
              Aucune carte de crédit requise
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} id="checkbox" />
              Pas de logiciel à installer
            </div>
          </div>
        </div>
        {showGirl && (
          <div className="tableImage">
            <div className="filleWrapper">
              <Image
                src={fille}
                alt="gestionnaire de patrimoine sur Bordeaux"
                className="fille"
                placeholder="blur"
                priority
              />
            </div>
            <div className="floaters img_header_dyn_gauche">
              <FontAwesomeIcon icon={faBuilding} />
              <p>Immobilier</p>
              <p className="price">350 000€</p>
              <p className="evolution">+4,9% sur 1 an</p>
            </div>
            <div className="floaters img_header_dyn_droite">
              <FontAwesomeIcon icon={faCreditCard} />
              <p>Placements</p>
              <p className="price">70 000€</p>
              <p className="evolution">+2,4% acquis sur 1 an</p>
            </div>
            <i>
              Lili-Rose Beaudouin <br />
              Assistante gestionnaire de patrimoine chez Easy Capital
            </i>
          </div>
        )}
      </div>

      <div>
        <h2 className="titre_carousel" data-aos={isDesktop ? "fade-up" : null}>
          Les principales fonctions de l'outil
        </h2>
        <p className="petit_titre" data-aos={isDesktop ? "fade-up" : null}>
          Faites les bons choix dans votre gestion de patrimoine
        </p>

        <Tabs
          defaultActiveKey="1"
          className="ant-tabs"
          animated={{ inkBar: true, tabPane: isDesktop }}
          tabPosition={isDesktop ? "top" : "left"}
        >
          <TabPane tab="Agréger votre patrimoine" key="1">
            <div className="page_carousel">
              <div className="page_carousel_droite">
                <h3
                  className="titrePageCarousel"
                  data-aos={isDesktop ? "fade-down" : null}
                >
                  Agréger <br /> votre patrimoine
                </h3>

                <p
                  className="text_carousel"
                  data-aos={isDesktop ? "fade-up" : null}
                >
                  Ajouter tous vos biens immobiliers, vos contrats ainsi que
                  votre épargne. Tout ça sur une seule et même interface
                  sécurisé. Simple et rapide.
                </p>
              </div>
              <div
                className="page_carousel_gauche"
                // data-aos={isDesktop ? "fade-up" : null}
              >
                <Image
                  src={agreger}
                  alt="aperçu de l'outil de gestion de patrimoine"
                  placeholder="blur"
                  priority
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Suivre son patrimoine" key="2">
            <div className="page_carousel">
              <div className="page_carousel_droite">
                <h3
                  className="titrePageCarousel"
                  data-aos={isDesktop ? "fade-down" : null}
                >
                  Suivre <br /> son patrimoine
                </h3>

                <p
                  className="text_carousel"
                  data-aos={isDesktop ? "fade-up" : null}
                >
                  Suivez l’évolution de vos investissements pour prendre les
                  bonnes décisions ou optimiser vos placements financiers.
                </p>
              </div>
              <div
                className="page_carousel_gauche"
                // data-aos={isDesktop ? "fade-up" : null}
              >
                <Image
                  src={suivre_votre_patrimoine}
                  alt="homme conseillant un couple sur la gestion de son patrimoine"
                  placeholder="blur"
                  priority
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Diversifier ses investissements" key="3">
            <div className="page_carousel">
              <div
                className="page_carousel_droite"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3
                  className="titrePageCarousel"
                  data-aos={isDesktop ? "fade-down" : null}
                >
                  Diversifier <br /> ses investissements
                </h3>

                <p
                  className="text_carousel"
                  data-aos={isDesktop ? "fade-up" : null}
                >
                  Diversifiez vos placements financiers afin de diminuer le
                  risque de perte lié à une classe d’actif, une zone
                  géographique ou un établissement bancaire.
                </p>
              </div>
              <div
                className="page_carousel_gauche"
                // data-aos={isDesktop ? "fade-up" : null}
              >
                <Image
                  src={analyse_diversification}
                  alt="Personne analysant ses différentes possibilités d'investissements"
                  placeholder="blur"
                  priority
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Calculer rendement locatif" key="4">
            <div className="style_carousel">
              <div className="page_carousel">
                <div className="page_carousel_droite">
                  <h3
                    className="titrePageCarousel"
                    data-aos={isDesktop ? "fade-down" : null}
                  >
                    Calculer <br /> rendement locatif
                  </h3>
                  <p
                    className="text_carousel"
                    data-aos={isDesktop ? "fade-up" : null}
                  >
                    Calculez votre rendement net pour votre investissement
                    immobilier locatif. L’outil de gestion de patrimoine vous
                    permettra de la comparer à toute autre solution.
                  </p>
                </div>
                <div
                  className="page_carousel_gauche"
                  // data-aos={isDesktop ? "fade-up" : null}
                >
                  <Image
                    src={calcul_rendement_locatif}
                    alt="Personne calculant son rendement locatif"
                    placeholder="blur"
                    priority
                  />
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Calculer frais de transmission" key="5">
            <div className="page_carousel">
              <div className="page_carousel_droite">
                <h3
                  className="titrePageCarousel"
                  data-aos={isDesktop ? "fade-down" : null}
                >
                  Calculer <br /> les frais de transmission
                </h3>

                <p
                  className="text_carousel"
                  data-aos={isDesktop ? "fade-up" : null}
                >
                  Estimer les frais et la répartition de votre patrimoine en cas
                  de décès. Diminuez ces frais et organisez au mieux vos actifs.
                </p>
              </div>
              <div
                className="page_carousel_gauche"
                // data-aos={isDesktop ? "fade-up" : null}
              >
                <Image
                  src={frais_transmission}
                  alt="Famille préparant la transmission de son patrimoine"
                  placeholder="blur"
                  priority
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="how_it_works">
        <h2 className="titre_steps" data-aos={isDesktop ? "fade-down" : null}>
          Comment ça marche ?
        </h2>
        <p className="detail">
          Les différentes étapes de votre gestion de patrimoine en ligne
        </p>
        <div className="section_steps">
          <div className="steps_gauche" data-aos={isDesktop ? "fade-up" : null}>
            <div className="images_superpose">
              <Image
                src={comment_ca_marche}
                className="img_fixe"
                alt="Les différentes étapes de votre gestion de patrimoine en ligne"
                placeholder="blur"
                priority
              />
            </div>
          </div>

          <div className="steps">
            <Steps direction="vertical" current={-1}>
              <Step
                title="Je renseigne mon patrimoine"
                description={
                  "Agrégez vos comptes ou ajouter manuellement l’ensemble de votre patrimoine qu’il soit financier ou immobilier."
                }
                data-aos={isDesktop ? "fade-down" : null}
              />
              <Step
                title={
                  <>
                    Je définis mon <strong>profil investisseur</strong>
                  </>
                }
                description="Définissez votre profil investisseur pour analyser si vos investissements actuels sont en adéquation avec vos attentes."
                data-aos={isDesktop ? "fade-down" : null}
              />
              <Step
                title="J'obtiens des recommandations"
                description={
                  "Notre outil de gestion de patrimoine génère des recommandations pour mieux diversifier vos placements, diminuer vos frais ou encore mieux protéger vos proches."
                }
                data-aos={isDesktop ? "fade-down" : null}
              />
              <Step
                title="Je simule mes futurs investissements"
                description={
                  <>
                    Projetez vos futurs investissements financiers ou
                    immobiliers. Prenez conseil avec un
                    <strong> expert en patrimoine</strong>
                  </>
                }
                data-aos={isDesktop ? "fade-down" : null}
              />
            </Steps>
          </div>
        </div>
      </div>
      <div className="roleGestionnaire">
        <h2>Le rôle du gestionnaire de patrimoine</h2>
        <div>
          <div>
            <p>
              Nos conseillers étudient au quotidien pour vous les meilleures{" "}
              <strong>pistes d’investissements</strong>, adaptées à votre
              situation fiscale.
            </p>
            <p>
              En visio ou au sein de nos locaux, nous vous offrons votre premier{" "}
              <strong>bilan patrimonial</strong> afin de bâtir la base de votre
              projet.
            </p>
            <p>
              De l’immobilier aux différents placements financiers, nous sommes
              en mesure de vous proposer les{" "}
              <strong>meilleures solutions du marché</strong>.
            </p>
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              Commencer à gérer avec un conseiller
            </Button>
          </div>
          <div>
            <Image
              src={iceberg}
              alt="Les missions du conseiller en gestion de patrimoine"
              placeholder="blur"
              width={600}
              height={600}
              priority
            />
          </div>
        </div>
      </div>
      <div className="section_metier_wrapper">
        {isDesktop && (
          <>
            <div id="cercle_vert" data-aos={isDesktop ? "fade-up" : null} />
            <div id="cercle_noir" data-aos={isDesktop ? "fade-up" : null} />
            <div id="cercle_jaune" data-aos={isDesktop ? "fade-up" : null} />
            <div id="cercle_bleu" data-aos={isDesktop ? "fade-up" : null} />
          </>
        )}
        <div className="section_Metiers">
          <h2 className="titre_metiers_orange">
            Toutes les solutions du marché sont ici
          </h2>
          <h3 className="petit_titre">Vous souhaitez commencer à investir ?</h3>

          <div className="section_metiers">
            <div className="metiers_col1">
              <div
                className="bloc_metier_left"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_left">Immobilier</h3>
                Optez pour le meilleur régime fiscal dans l'immobilier neuf ou
                ancien.
              </div>
              <div
                className="bloc_metier_left"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_left">SCPI</h3>
                65 SCPI sans surcoût pour vous diversifier sans redondance.
              </div>
              <div
                className="bloc_metier_left"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_left">Crédit</h3>
                Aide au montage de votre dossier pour obtenir les meilleurs
                taux.
              </div>
            </div>
            <div className="metiers_col2">
              <Image
                src={solutions_du_marche}
                className="img_metiers"
                alt="femme consultant toutes les solutions du marché"
                height={600}
                placeholder="blur"
                priority
              />
              <div
                className="bloc_metier_middle"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_middle">Epargne entreprise</h3>
                Nous vous accompagnons pour le montage de votre plan d'épargne
                entreprise.
              </div>
            </div>
            <div className="metiers_col3">
              <div
                className="bloc_metier"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_right">Assurance vie</h3>
                Les meilleurs contrats retenus pour leurs atouts, en adéquation
                avec votre profil.
              </div>
              <div
                className="bloc_metier"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_right">Défiscalisation</h3>
                Forêts, vignes, Girardin, FIP/FCPI nous choisissons le plus
                adéquat.
              </div>
              <div
                className="bloc_metier"
                data-aos={isDesktop ? "fade-up" : null}
              >
                <h3 className="metier_titre_right">Prévoyance</h3>
                Nous comparons chaque année tous vos contrats pour le meilleur.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pourquoi_un_outil">
        <h2 data-aos={isDesktop ? "fade-up" : null}>
          Pourquoi un outil de gestion de <br />
          patrimoine en ligne ?
        </h2>
        <div>
          <div
            className="page_carousel_gauche"
            data-aos={isDesktop ? "fade-up" : null}
          >
            <Image
              src={pourquoi_un_outil}
              alt="Pourquoi un outil de gestion de patrimoine en ligne"
              placeholder="blur"
              priority
            />
          </div>
          <p data-aos={isDesktop ? "fade-left" : null}>
            <b>Avoir un gestionnaire de patrimoine</b> ne suffit plus. Notre
            outil en ligne vous permet d’avoir une vision globale de vos
            investissements. Prenez les bonnes décisions en toute connaissance
            de cause. <br />
            <br />
            Notre formulaire ainsi que notre agrégateur vous permet de remplir
            votre profil en quelques minutes. <br />
            <b>
              Vous souhaitez préparer votre transmission ? Réduire vos impôts ?
              Préparer votre retraite ?
            </b>{" "}
            <br />
            <br />
            Toutes ces solutions sont disponible sur notre{" "}
            <b>outil de gestion de patrimoine</b>.
          </p>
        </div>
      </div>
      <div className="fin">
        <div className="text_fin">
          Tous vos<strong> biens immobiliers </strong> et financiers analysés
          chaque jour pour
          <strong> diversifier, optimiser et économiser.</strong>
        </div>

        <div className="footer">
          <div className="footer_img">
            <Image src={orias} alt="logo Orias" />
          </div>
          <div className="footer_img">
            <Image src={cncgp} alt="logo de la CNCGP" />
          </div>
          <div className="footer_img">
            <Image src={rgpd} alt="logo de la RGPD" />
          </div>
        </div>
      </div>
      <div className="faq">
        <div className="titre_faq">
          <p id="weAre" data-aos={isDesktop ? "fade-up" : null}>
            Nous sommes là pour vous aider
          </p>
          <h2 id="Freq" data-aos={isDesktop ? "fade-up" : null}>
            Foire aux questions
          </h2>
        </div>

        <div className="collapse" data-aos={isDesktop ? "fade-up" : null}>
          <Collapse
            accordion
            defaultActiveKey={["1"]}
            ghost
            expandIconPosition={"up"}
          >
            <Panel
              header="Quelle différence entre une banque et un gestionnaire de patrimoine ?"
              key="1"
            >
              <p>
                Les banques ne sont désormais plus des interlocuteurs fiables
                par manque de formation et de moyens humains. Le conseiller en
                gestion de patrimoine est amené à subir la complexité
                administrative ce qui lui impose de réduire son offre. Un
                accompagnement proche et personnalisé amène le conseiller à
                gérer complètement ses clients.
              </p>
            </Panel>
            <Panel
              header="Suis-je obligé de souscrire des placements avec vous ?"
              key="2"
            >
              <p>
                Nous avons choisi un modèle où un abonnement est nécessaire pour
                accéder à toutes nos fonctions. A ce titre, vous n'avez aucune
                obligation de souscrire à une solution de placement. C'est
                simple et transparent.
              </p>
            </Panel>
            <Panel
              header="Suis-je engagé avec Easy Capital dans le temps ?"
              key="3"
            >
              <p>
                Non, il n'y a pas d'engagement tant sur la partie conseil via
                notre application que lorsque vous réalisez des investissements
                financiers ou immobiliers. A nous de vous apporter satisfaction
                pour travailler longtemps ensemble.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>

      <div className="section_blog">
        <div className="titre_faq" data-aos={isDesktop ? "fade-up" : null}>
          <h2 className="titre_blog">Nos articles gestion de patrimoine</h2>
          <h3
            className="petit_titre_blog"
            data-aos={isDesktop ? "fade-up" : null}
          >
            Se renseigner pour effectuer les meilleurs investissements
          </h3>
        </div>
        <div className="blog">
          {articles.map((article, index) => {
            return (
              <ArticleGuides
                className="article"
                content={article.meta_description}
                title={article.title}
                date={article.created_at}
                img={article.photo?.photo}
                type={"small"}
                tags={article.tags}
                slug={article.slug}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <Footer />
      <Modal
        className="testOutilFormModal"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={
          isDesktop
            ? {
                display: "flex",
                alignItems: "center",
                height: 500,
                padding: 50,
              }
            : {}
        }
      >
        {isDesktop && (
          <div>
            <img src="/home/preview_start.png" />
          </div>
        )}
        <TestOutilForm
          title={
            <p>
              <span>Outil de gestion de patrimoine en ligne</span>
              <br />
              Easy Capital
            </p>
          }
          description={
            <p className="description">
              Gérez tout votre patrimoine sur une seule plateforme tout en
              profitant de conseils personnalisés selon votre situation.
            </p>
          }
          classname="home"
        />
      </Modal>
    </HomeWrapper>
  );
}
