import { FooterWrapper } from "./Footer.style";
import Link from "next/link";
import {
  FaFacebookSquare,
  FaGoogle,
  FaHeart,
  FaLinkedin,
} from "react-icons/fa";
import easycapital_logo from "../../../../public/easycapital_logo.svg";
import Image from "next/image";

export default function Footer({ className }) {
  return (
    <FooterWrapper className={className}>
      <div className="top">
        <div>
          <a href="#top">
            <Image
              src={easycapital_logo}
              alt="logo d'Easy Capital"
              width={200}
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/easy-capital/"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a href="https://www.facebook.com/cabineteasycapital" target="_blank">
            <FaFacebookSquare />
          </a>
        </div>
      </div>
      <hr />
      <div className="bottom">
        <div className="left">
          <a
            href="https://www.google.com/search?channel=fs&client=ubuntu&q=easycapital#lrd=0xd553accc8115555:0x16671e7b338f8b2d,3,,,"
            target="_blank"
            className="review"
          >
            <FaGoogle className="google" /> Votre avis compte pour nous{" "}
            <FaHeart className="smile" />
          </a>
          <div>
            <Link href="/rgpd">Traitement des données</Link>
            <Link href="/contact">Contact</Link>
          </div>
          <br />
          <Link href="/gestion-de-patrimoine-bordeaux">
            Gestion de patrimoine à Bordeaux
          </Link>
          <Link href="/gestion-de-patrimoine-tresses">
            Gestion de patrimoine à Tresses
          </Link>
          <Link href="/gestion-de-patrimoine-arcachon">
            Gestion de patrimoine à Arcachon
          </Link>
          <Link href="/gestion-de-patrimoine-bouscat">
            Gestion de patrimoine au Bouscat
          </Link>
        </div>
        <p className="contact">
          <a
            href="https://www.google.com/maps/place/70+Av.+de+Branne,+33370+Tresses/@44.8386245,-0.4915473,17z/data=!3m1!4b1!4m5!3m4!1s0xd55259d13976579:0x92eb1405bc36c6f3!8m2!3d44.8386245!4d-0.4893586"
            target="_blank"
          >
            70 Avenue de Branne 33370 TRESSES
          </a>
          <br />
          <a href="mailto:bonjour@easycapital.fr">bonjour@easycapital.fr</a>
          <a href="http://annuairepatrimoine.fr"></a>
        </p>
      </div>
    </FooterWrapper>
  );
}
