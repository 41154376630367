import styled from "styled-components";

export const TagWrapper = styled.div`
  .ant-btn {
    font-size: 16px;
    background: #788896;
    border-radius: 20px;
    margin-right: 10px;
    padding: 15px;
  }
`;
