import Home from "../containers/Home/Home";

const HomePage = (props) => {
  return <Home {...props} />;
};

export async function getStaticProps() {
  const res = await fetch(
    process.env.API_URL + "/articles/getThreeLast"
  );
  const articles = await res.json();

  return { props: { articles }, revalidate: 60 };
}

export default HomePage;
