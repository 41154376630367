import { Button } from "antd";
import { TagWrapper } from "./Tag.style";

export default function Tag({ title }) {
  return (
    <TagWrapper>
      <Button className="tag" type="primary">
        {title}
      </Button>
    </TagWrapper>
  );
}
