import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import HttpService from "../../services/HttpService";
import { TestOutilFormWrapper } from "./TestOutilForm.style";

export default function TestOutilForm({ title, description = "", classname }) {
  const [sendMailLoading, setSendMailLoading] = useState(false);

  const handleSendMail = (values) => {
    setSendMailLoading(true);
    const http = new HttpService();
    http
      .postData(values, "send-email-advice")
      .then((response) => {
        message.success(response);
        setSendMailLoading(false);
        setTimeout(() => {
          window.open("/start", "_ blank");
        }, 2000);
      })
      .catch((response) => {
        message.error(response);
        setSendMailLoading(false);
      });
  };

  return (
    <TestOutilFormWrapper className={classname}>
      <strong>{title}</strong>
      <hr />
      {description}
      <Form onFinish={handleSendMail}>
        <Form.Item
          type="email"
          name="email"
          required
          rules={[
            {
              required: true,
              message: "Veuillez entrer votre e-mail.",
            },
            {
              type: "email",
              message: "Veuillez entrer un e-mail valide.",
            },
          ]}
        >
          <Input placeholder="Adresse e-mail*" />
        </Form.Item>
        <Form.Item>
          <Button type="info" htmlType="submit" loading={sendMailLoading}>
            C'est parti !
          </Button>
        </Form.Item>
      </Form>
    </TestOutilFormWrapper>
  );
}
