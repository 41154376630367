import styled from "styled-components";

export const ArticleGuidesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .ant-card {
    border: none;
  }
  .ant-card-body {
    padding: 0px;
  }
  .articleWrapper {
    padding: 20px;
  }
  img {
    width: 100%;
  }
  .ligne1 {
    font-size: 18px;
    display: flex;

    .article_date {
      flex: 1;
    }
    .article_theme {
      flex: 1;
      font-weight: bold;
    }
  }
  .tags {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    .Button {
      flex: 1;
      width: 50px;
    }
    .readmore {
      border-radius: 20px;
      background-color: #40a9ff;
      padding: 15px 40px;
    }
  }
  .articleTitle {
    font-size: 24px;
    margin-bottom: 10px;
    color: #4ca6e2;
    font-weight: 700;
  }
  .content {
    color: #3e4c57;
    text-align: justify;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    > div {
      max-width: 380px;
    }
  }
`;
