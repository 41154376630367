import { ArticleGuidesWrapper } from "./ArticleGuides.style";
import { Card } from "antd";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import Tag from "../global/Tag/Tag";
import Link from "next/link";
import { Image } from "cloudinary-react"
import { Button } from "antd";
import { TagWrapper } from "../global/Tag/Tag.style";

export default function Guides({
  slug,
  img,
  title,
  content,
  date,
  type,
  tags,
  isEvent,
}) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <ArticleGuidesWrapper>
      <Card
        hoverable
        style={{ width: type == "small" ? (isMobile ? "100%" : "400px") : 865 }}
      >
        <Link href={(isEvent ? "/event/" : "/easynews/") + slug} passHref>
          <a>
            <Image cloudName="easycapital" src={img} className="img" alt="illustration d'article" />
            <div className="articleWrapper">
              <div className="ligne1">
                <div className="article_date">
                  {isEvent ? "Programmé" : "Publié"} le{" "}
                  {moment(date).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="articleTitle">{title}</div>
              {isEvent ? (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              ) : (
                <div className="content">{content}</div>
              )}
              <div className="tags readmore">
                <div>
                <TagWrapper>
                  <Button className="readmore" type="primary">
                    Lire l'article
                  </Button>
                </TagWrapper>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </Card>
    </ArticleGuidesWrapper>
  );
}
