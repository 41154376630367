import styled from "styled-components";
import { blue, mazarine, yellow } from "../../../styles/variables.style";

export const FooterWrapper = styled.div`
  background-color: #f6f7fa;
  padding-right: 20% !important;
  padding-left: 20% !important;
  padding-bottom: 20px;
  padding-top: 30px;

  > div {
    display: flex;
    justify-content: space-between;
  }

  p,
  a {
    font-size: 14px !important;
    margin-right: 10px;
    color: dimgrey;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: darkgrey;
    border: none;
    height: 1px;
  }

  .top {
    a {
      margin-right: 0px;
      svg {
        font-size: 24px;
        margin-left: 10px;
        fill: ${blue};
      }
    }
  }

  .bottom {
    .left {
      > div {
        display: flex;
        width: 255px;
        justify-content: center;
      }
      .review {
        color: ${mazarine};
        font-size: 14px !important;
        &:hover {
          font-weight: bold;
        }
        justify-content: center;
        width: 265px;
      }
      a {
        display: flex;
        align-items: center;
        .smile {
          fill: red;
          margin-left: 4px;
        }
        .google {
          fill: ${blue};
          margin-right: 4px;
        }
        &:hover {
          text-decoration: underline;
          font-style: italic;
        }
      }
    }
  }

  .contact {
    text-align: right;
  }

  @media (max-width: 1024px) {
    padding-left: 3% !important;
    padding-right: 3% !important;
    padding-bottom: 50px;
    .bottom {
      flex-direction: column;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      p {
        text-align: center;
      }
    }
  }
`;
